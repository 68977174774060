import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import SpecialBanner from "./SpecialBanner"

const imgQuery = graphql`
  {
    mob: file(relativePath: { eq: "Banners/newAuracard/mob.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    tab: file(relativePath: { eq: "Banners/newAuracard/tab.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    desk: file(relativePath: { eq: "Banners/newAuracard/desk.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    large: file(relativePath: { eq: "Banners/newAuracard/large.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

type Props = {
  logoInBanner?: string
  orderNum?: string
}

const offer = (
  <>
    ХАЛВА AURACARD — <br />
    всего одна карта <br /> для всего
  </>
)

const subTitle = (
  <>
    Все преимущества классической ХАЛВЫ <br /> плюс уникальные условия на покупки в ТРЦ&nbsp;«Аура»!
    <br />
    Карта выдаётся только в Новосибирске
  </>
)

export default function Banner({ logoInBanner, orderNum }: Props) {
  const imgData = useStaticQuery(imgQuery)

  return (
    <SpecialBanner
      imgData={imgData}
      title={offer}
      subTitle={subTitle}
      logoInBanner={logoInBanner}
      orderNum={orderNum}
    />
  )
}
